import Carousel from 'nuka-carousel';
import { marcomTheme } from '@components/themes';
import { Text } from '@components/typography';
import { CloudImage } from '@components/CloudImage';
import styled from 'styled-components';
import { themeMediaQuery } from '@utils/styledTheme.util';

export const StyledVerticalCarousel = styled(Carousel)`
  width: 600px !important;
  max-height: 925px !important;
  height: 100% !important;
  .slider-slide {
    margin-top: 0px !important;
  }
  .wedding-websites-dots-vertical-carousel-container {
    ${marcomTheme.mediaQueries.md} {
      display: inline !important;
      position: relative !important;
      top: -106px !important;
      right: 968px !important;
    }

    ${marcomTheme.mediaQueries.sm} {
      display: inline !important;
      top: -106px;
      right: 625px;
    }
  }
  ${themeMediaQuery('below_sm')} {
    height: 350px !important;
  }
`;

export const StyledCrouselWrapper = styled.div<{ backgroundColor: string }>`
  max-width: 800px;
  max-height: 925px;
  background-color: ${props => props.backgroundColor};
  ${themeMediaQuery('below_sm')} {
    height: 350px;
    width: 100%;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    z-index: 1;
  }
`;

export const StyledTitle = styled(Text)`
  ${marcomTheme.mediaQueries.sm} {
    padding-left: 25px;
    padding-right: 25px;
  }
  ${marcomTheme.mediaQueries.md} {
    padding-left: 125px;
    padding-right: 125px;
  }
  ${marcomTheme.mediaQueries.xl} {
    padding-left: 70px;
    padding-right: 70px;
  }
`;

export const StyledQuotesContainer = styled.div`
  margin-right: 75px;
  margin-top: -125px;
  max-width: 250px;
  float: right;
  ${themeMediaQuery('below_sm')} {
    margin-right: 0px;
    margin-top: 0px;
    width: 100%;
    padding-top: 30px;
  }
`;

export const StyledInactiveCarouselDot = styled.div`
  cursor: pointer;
  height: 8px;
  width: 8px;
  border: solid 1px white;
  border-radius: 50%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const StyledActiveCarouselDot = styled.div`
  cursor: pointer;
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const StyledDotsContainer = styled.div`
  width: 16px;
  padding-top: 120px;
  padding-left: 125px;

  ${marcomTheme.mediaQueries.md} {
    padding-left: 125px !important;
  }

  ${marcomTheme.mediaQueries.sm} {
    padding-left: 25px;
  }

  ${marcomTheme.mediaQueries.below_sm} {
    padding-left: 0px;
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
  }

  ul {
    ${marcomTheme.mediaQueries.below_sm} {
      width: 73px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
`;

export const StyledCloudImageWrapper = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  ${themeMediaQuery('below_sm')} {
    width: 100%;
  }
`;

export const StyledCloudImage = styled(CloudImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const VerticalCrouselWrapper = styled.div<{ isVarientRight: boolean }>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  display: flex;
  overflow: hidden;
  flex-direction: ${props => (props.isVarientRight ? 'row-reverse' : 'row')};
  ${themeMediaQuery('below_sm')} {
    flex-direction: ${props => (props.isVarientRight ? 'column' : 'column-reverse')};
  }
`;

export const TitleWrapper = styled.div`
  margin-top: 147px;
  height: 500px;
  ${themeMediaQuery('below_sm')} {
    margin-top: 0px;
    height: auto;
  }
`;

export const DateWrapper = styled(Text)`
  margin-bottom: 41px;
  ${themeMediaQuery('below_sm')} {
    margin-bottom: 15px;
  }
`;
