import styled from 'styled-components';
import { Box } from '@components/Box';
import { CTALink } from '@components/CTALink';
import { Text } from '@components/typography';
import { themeVariant, themeColor, themeMediaQuery, themeSpace } from '@utils/styledTheme.util';

export const StyledCTALink = styled(CTALink)<{ tabIndex: number }>``;

export const StyledTitleText = styled(Text)`
  white-space: normal;
`;

export const StyledTitleCTALink = styled(CTALink)`
  ${themeVariant('hed40')};
  color: ${themeColor('neutra6')};
  :hover ${StyledTitleText} {
    color: ${themeColor('linkHover')};
  }
  :active ${StyledTitleText} {
    color: ${themeColor('linkPress')};
  }
`;

export const StyledBox = styled(Box)`
  max-width: 516px;
  border-radius: 0px 64px 0px 0px;
  padding-top: 100px;
  padding-bottom: 68px;
  ${themeMediaQuery('below_sm')} {
    padding-left: ${themeSpace(7)};
    padding-right: ${themeSpace(7)};
    padding-top: 35px;
    padding-bottom: 35px;
  }
  ${themeMediaQuery('xl')} {
    max-width: 575px;
  }
`;

export const ImgContainer = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 170px;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  ${themeMediaQuery('below_sm')} {
    height: 350px;
    margin: 0 0px;
    position: relative;
    left: 0px;
  }
`;

export const Container = styled.div`
  position: relative;
  height: 100%;
  margin: 0 100px;
  ${themeMediaQuery('below_sm')} {
    margin: 0 0px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  left: 0;
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${themeMediaQuery('below_sm')} {
    position: relative;
    top: 18%;
  }
`;

export const CarouselWrap = styled.div`
  position: relative;
  .slider-list {
    cursor: default !important;
    height: 935px !important;
    ${themeMediaQuery('below_sm')} {
      height: 793px !important;
    }
  }
`;

export const PreviousSlideButton = styled.button`
  position: absolute;
  height: 100%;
  left: -100px;
  top: 0;
  background: transparent;
  width: 100px;
  z-index: 99;
  ${themeMediaQuery('below_sm')} {
    left: 0;
    width: 50px;
  }
`;

export const NextSlideButton = styled.button`
  position: absolute;
  height: 100%;
  right: -100px;
  top: 0;
  background: transparent;
  width: 200px;
  z-index: 99;
  ${themeMediaQuery('below_sm')} {
    right: 0;
    width: 50px;
  }
`;

export const CarouselIndicatorWrap = styled.div`
  position: absolute;
  bottom: 72px;
  left: 420px;
  z-index: 999;
  div + div {
    margin-left: ${themeSpace(5)};
  }
  ${themeMediaQuery('below_sm')} {
    position: unset;
    float: right;
    margin-right: 35px;
    margin-top: -59px;
  }
`;

export const Inactive = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  border: 2px solid ${themeColor('purple4')};
  border-radius: 50%;
`;

export const Active = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  border: 1px solid ${themeColor('purple4')};
  border-radius: 50%;
  background: ${themeColor('purple4')};
`;
