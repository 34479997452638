import React from 'react';
import Carousel from 'nuka-carousel';
import { CloudImage } from '@components/CloudImage';
import { Text } from '@components/typography';
import styled from 'styled-components';

export const StyledCarousel = styled(Carousel)`
  .wedding-websites-dots-container {
    margin-left: 1072px !important;
    margin-bottom: 20px !important;
  }
`;

export const StyledCarouselBackground = styled.div<{ backgroundColor?: string; variant?: string }>`
  width: 1224px;
  height: 539px;
  background-color: ${props => props.backgroundColor || null};
  border-radius: ${props => (props.variant === 'bottomRight' ? '4px 4px 96px 4px;' : '4px 96px 4px 4px;')};
  margin-top: 135px;
`;

export const TextCTA = styled(Text)`
  margin-top: 16px;
`;

export const StyledDeviceImage = styled(CloudImage)`
  width: 400px;
  height: 674px;
  position: relative;
  left: 50px;
  bottom: 650px;
`;

export const CarouselTextContainer = styled.div`
  position: relative;
  width: 534px;
  left: 100px;
  bottom: 450px;
`;
