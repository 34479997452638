import React, { useRef, useState, useLayoutEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { SpacingStack } from '@components/SpacingStack';
import { Text } from '@components/typography';
import {
  Container,
  ImgContainer,
  StyledBox,
  TextContainer,
  Inactive,
  Active,
  CarouselWrap,
  CarouselIndicatorWrap,
  PreviousSlideButton,
  NextSlideButton,
  StyledCTALink,
  StyledTitleCTALink,
  StyledTitleText,
} from './BlogCarousel.styles';
import Carousel from 'nuka-carousel';
import { blogCarouselData } from '@data-ts/components/BlogCarousel';
import { CarouselIndicator } from '@components/Carousel';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';
import { useIsMounted } from '@hooks/useIsMounted';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import ScrollMagic from 'scrollmagic';
import { CTALink } from '@components/CTALink';

interface Blog
  extends Readonly<{
    title: string;
    excerpt: string;
    mediaLink: string;
    link: string;
  }> {}

interface BlogCarouselProps
  extends Readonly<{
    blogs: ReadonlyArray<Blog>;
    sectionId: string;
  }> {}

export const BlogCarousel: React.FC<BlogCarouselProps> = ({ blogs, sectionId }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const isMobile = useThemeMediaQuery('below_sm');
  const isMounted = useIsMounted();
  const scrollController = useScrollController();

  useLayoutEffect(() => {
    let scene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    const sectionRefEl = sectionRef.current;
    if (scrollController && isMounted && sectionRefEl) {
      const initScene = () => {
        scene = new ScrollMagic.Scene({
          triggerElement: sectionRefEl,
          triggerHook: 'onEnter',
          offset: sectionRefEl.clientHeight / 1.5,
        })
          .addTo(scrollController)
          .on('enter', () => {
            setSlideIndex(1);
          });
      };
      initScene();
    }
    const clearScene = () => {
      if (scene) {
        scene.destroy(true);
      }
    };
    return clearScene;
  }, [scrollController, isMounted]);

  const handleActiveSlide = (value: number) => {
    setSlideIndex(value);
  };

  const inactiveComponent = () => {
    return <Inactive></Inactive>;
  };

  const activeComponent = () => {
    return <Active></Active>;
  };

  const handlePreviousOrNextSlide = (typeOfButton: 'prev' | 'next') => {
    if (typeOfButton === 'prev') {
      if (slideIndex > 0) {
        setSlideIndex(slideIndex - 1);
      } else {
        setSlideIndex(blogs.length - 1);
      }
    } else {
      if (slideIndex < blogs.length - 1) {
        setSlideIndex(slideIndex + 1);
      } else {
        setSlideIndex(0);
      }
    }
  };

  return (
    <CarouselWrap ref={sectionRef} id={sectionId}>
      <Carousel
        slideIndex={slideIndex}
        withoutControls={true}
        initialSlideHeight={isMobile ? 793 : 935}
        style={{ backgroundColor: '#fffbf7' }}
        afterSlide={params => setSlideIndex(params)}
        speed={500}
      >
        {blogs.map((blog, index) => (
          <Container key={blog.title}>
            <PreviousSlideButton
              tabIndex={slideIndex === index ? 0 : -1}
              aria-label="Previous Slide Button"
              onClick={() => handlePreviousOrNextSlide('prev')}
            />
            <LazyLoadWrapper>
              <ImgContainer src={blog.mediaLink} />
            </LazyLoadWrapper>
            <TextContainer>
              <StyledBox paddingX={[10]} backgroundColor="yellow1">
                <SpacingStack spacing={6}>
                  <Text color="humanaDark4" variant="sectionEyeBrow24" tagName="h3">
                    {blogCarouselData.tipsAndTricks}
                  </Text>
                  <StyledTitleCTALink id={`blog-title-${blog.title}`} href={blog.link}>
                    <StyledTitleText color="neutra6" variant="hed40" tagName="span">
                      {ReactHtmlParser(blog.title)}
                    </StyledTitleText>
                  </StyledTitleCTALink>
                  <Text color="neutra5" variant="dek20" tagName="span">
                    {ReactHtmlParser(blog.excerpt)}
                  </Text>

                  <StyledCTALink tabIndex={slideIndex === index ? 0 : -1} id={`blog${blog.title}`} href={blog.link} hasChevron>
                    <Text variant="dek20" aria-label={blog.title} style={{ fontWeight: 'bold' }} color="actionPurple3" tagName="span">
                      {blogCarouselData.readMore}
                    </Text>
                  </StyledCTALink>
                </SpacingStack>
              </StyledBox>
              <CarouselIndicatorWrap>
                <CarouselIndicator
                  tabIndex={slideIndex === index ? 0 : -1}
                  quantity={blogs.length}
                  dotsPerPage={blogs.length}
                  infinite={false}
                  activeIndex={slideIndex}
                  onDotSelected={handleActiveSlide}
                  renderDot={inactiveComponent()}
                  renderDotActive={activeComponent()}
                />
              </CarouselIndicatorWrap>
            </TextContainer>
            <NextSlideButton
              tabIndex={slideIndex === index ? 0 : -1}
              aria-label="Next Slide Button"
              onClick={() => handlePreviousOrNextSlide('next')}
            />
          </Container>
        ))}
      </Carousel>
    </CarouselWrap>
  );
};
