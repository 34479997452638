import React from 'react';
import styled from 'styled-components';

type CarouselIndicatorProps = Readonly<{
  /* Default color of inactive indicator dots */
  indicatorColor?: string;
  /* Color of the active indicator dots */
  activeColor?: string;
  /* Total number of dots */
  quantity: number;
  /* Shape of the default indicator dots */
  shape?: 'square' | 'circle';
  /* Index of dot to make active. */
  activeIndex?: number;
  /* className to use for active indicator dot. */
  activeClassName?: string;
  /* Callback for when a dot is selected. Returns: index of selected dot. */
  onDotSelected?: Function;
  /* Override of indicator to render (if you wish to use your own component.) */
  renderDot?: JSX.Element;
  /* Override of indicator to render for active dots (if you wish to use your own component for active dots.) */
  renderDotActive?: JSX.Element;
  // TODO: Implement infinite / magic dots logic.
  // Would be really cool if we can pass in a react Spring transition here to use when changing dots.
  /* How many dots to show at once. e.g. if there are 20 total dots and you want to render only 5 */
  dotsPerPage: number;
  /* Infinite Dots- Magic dots effect like that of Instagram. */
  infinite?: boolean;
  tabIndex?: number;
}>;

const StyledIndicator = styled.div<{ isActive: boolean; shape: string; activeColor: string; indicatorColor: string }>`
  width: 12px;
  height: 12px;
  background-color: ${props => (props.isActive ? props.activeColor : props.indicatorColor)};
  margin: 5px;
  display: flex;
  border-radius: ${props => (props.shape === 'circle' ? '50%' : '0')};
`;

const StyledCircle = styled.div`
  cursor: pointer;
  display: inline-block;
`;

function onKeyPressHandler(): void {
  // TODO: Implement me and figure out best way to handle things for a11y purposes.
}

type ClickableProps = {
  onClick: Function;
  // TabIndex to use for a11y
  tabIndex?: number;
};

const ActiveComponent: React.FC<ClickableProps> = props => {
  return (
    <StyledCircle
      role="button"
      aria-label="Carousel Indicator"
      onKeyPress={onKeyPressHandler}
      tabIndex={props.tabIndex}
      onClick={() => props.onClick()}
    >
      {props.children}
    </StyledCircle>
  );
};

const InactiveComponent: React.FC<ClickableProps> = props => {
  return (
    <StyledCircle
      role="button"
      aria-label="Carousel Indicator"
      onKeyPress={onKeyPressHandler}
      tabIndex={props.tabIndex}
      onClick={() => props.onClick()}
    >
      {props.children}
    </StyledCircle>
  );
};

export const CarouselIndicator: React.FC<CarouselIndicatorProps> = props => {
  const selectedIndex = props.activeIndex || 0;

  function onDotSelected(index: number): void {
    if (props.onDotSelected) {
      props.onDotSelected(index);
    }
  }

  function renderDots(): JSX.Element {
    let dots = new Array<JSX.Element>();

    for (let i = 0; i < props.quantity; i++) {
      if (props.renderDot && props.renderDotActive) {
        if (i === selectedIndex) {
          dots.push(
            <ActiveComponent tabIndex={props.tabIndex} key={i} onClick={() => onDotSelected(i)}>
              {props.renderDotActive}
            </ActiveComponent>
          );
        } else {
          dots.push(
            <InactiveComponent tabIndex={props.tabIndex} key={i} onClick={() => onDotSelected(i)}>
              {props.renderDot}
            </InactiveComponent>
          );
        }
      } else {
        // Use the default indicator component if no active / inactive dots are passed in.
        dots.push(
          <StyledIndicator
            activeColor={props.activeColor ? props.activeColor : 'blue'}
            indicatorColor={props.indicatorColor ? props.indicatorColor : 'red'}
            shape={props.shape ? props.shape : 'circle'}
            isActive={i === selectedIndex}
            key={i}
            onClick={() => onDotSelected(i)}
          ></StyledIndicator>
        );
      }
    }

    return <>{dots}</>;
  }

  return <>{renderDots()}</>;
};

export default CarouselIndicator;
